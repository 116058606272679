module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"topLevelImportPaths":["@xstyled/styled-components"],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"xstyled","short_name":"xstyled","start_url":"/","display":"minimal-ui","icon":"images/logo-manifest.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"35747667c057871bf481acaf8d249528"},
    },{
      plugin: require('../../node_modules/smooth-doc/gatsby-browser.js'),
      options: {"plugins":[],"name":"xstyled","author":"Greg Bergé","description":"A utility-first CSS-in-JS framework built for React.","siteUrl":"https://xstyled.dev","githubRepositoryURL":"https://github.com/gregberge/xstyled","baseDirectory":"/opt/build/repo","navItems":[{"title":"Docs","url":"/docs/"}],"sections":["Getting Started","Core Concepts","Customization","Layout","Flexbox","Grid","Box Alignment","Spacing","Sizing","Typography","Backgrounds","Borders","Effects","Tables","Transitions","Animations","Transforms","Interactivity"],"carbonAdsURL":"//cdn.carbonads.com/carbon.js?serve=CE7IL2JN&placement=xstyleddev","docSearch":{"appId":"YTUZ8SLPDN","apiKey":"5b2a313e57330d1d333ff8d2e937005d","indexName":"smooth-code-xstyled"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-154496255-3","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
